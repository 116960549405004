@font-face{
  font-family: 'Ubuntu';
  src: local('Ubuntu'), url(./misc/fonts/ubuntu/Ubuntu-R.ttf) format('truetype');
}

@font-face{
  font-family: 'Digital';
  src: local('Digital'), url(./misc/fonts/digital/DS-DIGI.TTF) format('truetype');
}
/* components------------------------------------------------------------------------------------------ */
body {
  background-color: #212121;
  font-family: Ubuntu;
  height: 100%;
  width: 99.5%;
}

.technologies{
  background-color: #4CAF50;
  border: none;
  padding: 1px 2px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 1vmin 0.5vmin;
  border-radius: 1vmin;
}

.list-row{
  padding: 0 0;
  margin: 0 0;
  width: 80%;
}
.list-jobs{
  list-style-type: none;
}

/* Colours ------------------------------------------------------------------------------------------*/
.depth-one{
  background-color: black;
}

.depth-two{
  background-color: #212121;
}

.depth-three{
  background-color: #303030;
}

.depth-four{
  background-color: #424242;
}

.primary-text{
  font-family: Ubuntu;
  color: white;

}

.secondary-text{
  font-family: Ubuntu;
  color: white;
  opacity: 0.8;
}

.tertiary-text{
  font-family: Ubuntu;
  color: white;
  opacity: 0.7;
}

.hint-text{
  font-family: Ubuntu;
  color: white;
  opacity: 0.5;
}
.contrast-text-primary{
  color: #04ac00;
  opacity:0.8;
}

.contrast-text-secondary{
  color: #04ac00;
  opacity:0.7;
  text-decoration: none;
}

.contrast-text-tertiary{
  color: #04ac00;
  opacity:0.5;
}

.secondary-text-soft {
  color: #026700;
  font-family: Ubuntu;
}

/* Containers ------------------------------------------------------------*/

.footer{
  display: flex;
  width: 100%;
  height:10%;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: black;
  z-index: -1;
  bottom: -10px;
  }
.icon-container{
  bottom: 2%;
  right: 2%;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.projects-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between ;
  background-color: transparent;
  margin-top: 1em;
}


.page-container{
  min-height: 100%;
  height: 100vh;
  width: 80vw;
  position: relative;
  margin: 0 auto;
  display: flex;
}

.page-container-expand{
  min-height: 100%;
  width: 80vw;
  position: relative;
  margin: 0 auto;
  display: flex;
}

.container-left{
  flex-direction: column;
  right: 40%;
  position: static;
  flex-basis: 70%;
}
.container-right{
  justify-content:flex-start;
  flex-direction: column;
  position: static;
  flex-basis: 30%;
}
.row-container {
  display: flex;
  flex-flow: row;
  position: static;
}

.column-container{
  display: flex;
  flex-flow: column;
  position: static;
}

.flex-center-vertically{
  display: flex;
  justify-content: center;
  flex-direction: column;
  top: 20%;
  position: absolute;
}

.nav-container{
  bottom: 23px;
  left: -28px;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.job-description-container{
  width: 80%;
  height:100%;
  position: relative;
}


.job-index-container{
  width: 20%;
  height: 100%;
  position: relative;
}

.component-container{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height:100%;
  width:100%;
  padding: 1em;
}

.projectInfo-container{
  width:80%;
  position:relative;
  padding:1em;
  margin: 1em auto;
  display: flex;
  flex-direction: column;
}

.work-order{
  flex-direction: row;
  padding-top: 80px ;
}

/* Strings ------------------------------------------------------------*/

.name {
  font-family: arial;
  font-size: 10vmin;
  font-style: normal;
  font-weight: bold;
  line-height: 1.1em;
  text-align: left;
  text-decoration: none; 
}

.index {
  font-family: Ubuntu;
  font-size: 5vh;
  font-style: normal;
  font-weight: bold;
  line-height: 1.1em;
  text-align: left;
  text-decoration: none; 
}

.title {
    font-family: Ubuntu;
    font-size: 5vh;
    font-style: normal;
    font-weight: bold;
    line-height: 1.1em;
    text-align: left;
    text-decoration: none; 
    padding-top: 10%;
    padding-bottom: 5%;}

.sub-title { 
    font-family: Ubuntu;
    font-size: 2.2vh;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4em;
    text-align: left;
    text-decoration: none; }

.counter {
    font-family: Digital;
    float:right;
  }

.main-text {
    font-family: Ubuntu;
    font-size: 25px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4em;
    text-align: left;  
    text-decoration: none; }

.secondary-text-size {
    font-family: Ubuntu;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4em;
    text-align: left;  
    text-decoration: none; 
  }

  .tertiary-text-size {
    font-family: Ubuntu;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4em;
    text-align: left;  
    text-decoration: none; 
  }
  .primary-text-size-c {
    font-family: Ubuntu;
    font-size: 25px;
    font-style: normal;
    font-weight: normal;
    text-align: left;  
    text-decoration: none; 
  }

  .secondary-text-size-c {
    font-family: Ubuntu;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    text-align: left;  
    text-decoration: none; 
  }

  .tertiary-text-size-c {
    font-family: Ubuntu;
    font-size: 50%;
    font-style: normal;
    font-weight: normal;
    text-align: left;  
    text-decoration: none; 
  }

  .footer-text{
  color:#ffffff6b;
  font-family: Ubuntu;
  font-size: 1.8vw;;

  }

.university {
  color: #04ac00;
}

.university:hover {
  color: #cac5c5;
}

.nav-link {
  color: #cac5c5;
  padding-top: 1px;
  padding-left: 0px;
  background: transparent;
  display:inline-block;
}
.nav-link:hover{
  color: #5fe78d;
}
.nav-link > .active{
  color: #04ac00;
}
.job-link{
  color: #cac5c5;
  padding-right: 10px;
  text-decoration: none;
}

.job-link > .active{
  text-decoration:line-through;
  color: #5fe78d;
}

.job-link:hover{
  color: #5fe78d;
}
/* Icons ------------------------------------------------------------*/

.icon {
  color: #cac5c5;
  width: 20px;
  height: 20px;
  background: transparent;
  margin:1vmin;
}

.icon-proj {
  color: #cac5c5;
  width: 20px;
  height: 20px;
  background: transparent;
  margin-left:1vmin;
  padding-top: 0;
}

.icon-proj:hover{
  color: #ece6e6;
}


.icon:hover{
  color: #5fe78d;
  width: 26px;
  height: 26px;
  margin:1.5vmin;

}

.home-arrow{
  bottom: 10%;
  position: absolute;
  padding-left: 45%;
}


.progress-icon {
  position: fixed;
  bottom: 21px;
  left: -5px;
  width: 105px;
  height: 10vh;
}

/* Images ------------------------------------------------------------*/

.logo {
  top: 2%;
  left: 2%;
  width: 5vmin;
  height: 5vmin;
  background: transparent;
  position: fixed;}

  .img{
    height: 300px;
    max-width: 300px;
    border-radius: 4px;
    margin-left: 15%;
    box-shadow: 15px 15px #303030;
  }
  
  .img:hover{
    margin-left: 15%;
    box-shadow: 10px 10px #2a2a2a;
  }

  /* BUttons */

  .button {
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    background-color: transparent;
    color: #4CAF50;
    border: 2px solid #4CAF50; /* Green */
    font-size: 25px;
    height: 45px;
    max-width: 100%;
    position: relative;
    margin-bottom: 1em;
    padding: auto;
    text-decoration: none;
  }
  
  
  .button:hover {
    background-color: #4CAF50; /* Green */
    color: black;
  }

  .second-skills{
    margin-left: 10%;
  }

/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */

/* Media Dimensions */
@media (max-width: 600px) {

  .progress-icon {
    display:none;
  }

  .row-container-phone{
    flex-flow: column;
    padding-top: 10%;
  }

  .second-skills{
    margin-left: 0%;
  }

  .quote{
    display: none;
  }

/* Containers ------------------------------------------------------------*/

.icon-container{
  bottom: 20px;
  right: 10px;
}

.work-order{
  flex-direction: column;
  padding-top: 0px ;
}


.page-container-mobile{
  min-height: 100%;
  width: 80vw;
  position: relative;
  margin: 0 auto;
  display: flex;
  margin-bottom: 200px;
  justify-content: space-between;
}

.job-description-container{
  width: 90%;
}

.projectInfo-container{
  width:90%;
}



/* Strings ------------------------------------------------------------*/

.name {
  font-size: 10vh;
}

.sub-title { 
    font-size: 18px;}

.main-text {
    font-size: 15px;
 }


  .footer-text{
  font-size: 15px;
  }

.nav-link {
  z-index: 3;
}


/* Images ------------------------------------------------------------*/

.logo {
  width: 50px;
  height: 50px;
}

  .img{
    height: 100px;
    max-width: 200px;
    box-shadow: 5px 5px #303030;
  }
  
  .img:hover{
    margin-left: 15%;
    box-shadow: 3px 3px #2a2a2a;
  }


}